<template>
  <div>
    <app-Main>
      <template v-slot:main-content>
        <div class="manage">
          <div class="agent_no">
            <div class="title">分站加盟，即刻拥有属于自己的网站~</div>
            <div class="agent_subtitle">网站完全独立控制，管理操作简单；全天24小时自动售单，无需人工干预。</div>
            <div class="agent_advantage">
              <div class="agent_advantage_title">加盟优势：</div>
              <div class="agent_all_item">
                <div class="item_one">
                  <div class="icon icon1"></div>
                  <span>提供技术支持，24小时技术在线维护</span>
                </div>
                <div class="item_one">
                  <div class="icon icon2"></div>
                  <span>建站快，10分钟即可实现建站、上手运营网站</span>
                </div><div class="item_one">
                  <div class="icon icon3"></div>
                  <span>利润直达账户，提现24小时内到账</span>
                </div>
                <div class="item_one">
                  <div class="icon icon4"></div>
                  <span>独立域名，独立后台自由管理</span>
                </div>
                <div class="item_one">
                  <div class="icon icon5"></div>
                  <span>免费提供网站API接口，无需任何费用</span>
                </div>
                <div class="item_one">
                  <div class="icon icon6"></div>
                  <span>前景可观，客源稳定，收入稳定</span>
                </div>
                <div class="item_one">
                  <div class="icon icon7"></div>
                  <span>分站成本低，提供服务器、域名维护、升级服务</span>
                </div>
                <div class="item_one">
                  <div class="icon icon8"></div>
                  <span>提供稳定、安全、低价的快递费+礼品货源</span>
                </div>
              </div>
            </div>
            <div class="agent_step_title">开通分站流程：</div>
            <div class="agent_step">
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title">①注册账号</div>
                  <div class="item_desc">注册会员账号，若已有账号，直接在首页进行登录</div>
                </div>
                <div class="item_right"></div>
              </div>
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title blue">②申请代理</div>
                  <div class="item_desc">进入礼品商城，在“网站管理”点击“开通”申请分站</div>
                </div>
                <div class="item_right"></div>
              </div>
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title">③等待审核</div>
                  <div class="item_desc">按照操作流程，点击付费</div>
                </div>
                <div class="item_right"></div>
              </div>
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title blue">④开通成功</div>
                  <div class="item_desc">对已上线的分站进行运营管理、推广管理</div>
                </div>
                <div class="item_right"></div>
              </div>
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title">⑤网站配制</div>
                  <div class="item_desc">系统审核通过后，分站即开通成功，可立即使用</div>
                </div>
                <div class="item_right"></div>
              </div>
              <div class="step_item">
                <div class="item_left">
                  <div class="item_title blue">⑥推广盈利</div>
                  <div class="item_desc">设置网站域名、网站名称、推广佣金，QQ号等信息，等待系统审核</div>
                </div>
              </div>
            </div>
            <el-button @click="rightNowOpening" type="primary">联系客服开通分站（仅需￥800元）</el-button>
            <div class="btmtxt">现在开通，马上拥有属于您的独立域名网站、全天24小时自动售单</div>
          </div>
        </div>
        <div>
          <pay-dialog :dialogShow="dialogShow" name="开通分站" :price="price" :money="money" @closePayDialog="closePayDialog"/>
        </div>
      </template>
    </app-Main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import PayDialog from 'components/payDialog/PayDialog'
import { getUserMoney } from 'network/api'

export default {
  components: {
    AppMain,
    PayDialog
  },
  data () {
    return {
      dialogShow: false,
      price: '800',
      money: ''
    }
  },
  created () {
    // 获取账户余额
    getUserMoney().then(res => {
      this.money = res.data.usermoney
    })
  },
  methods: {
    rightNowOpening () {
      this.$message.success('请联系客服开通分站')
      // this.dialogShow = true
    },
    closePayDialog () {
      this.dialogShow = false
    }
  }
}
</script>

<style scoped src="styles/views/oem/manage.css">
</style>
